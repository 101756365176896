import React, { ReactElement, useMemo, useCallback, useState } from 'react';
import axios from 'axios';
import cx from 'classnames';
import Input from '@UI/Input';
import Checkbox from '@UI/Checkbox';
import BaseButton from '@UI/BaseButton';
import Select from '@UI/Select';
import Link from '@UI/Link';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { graphql, useStaticQuery } from 'gatsby';
import Text, { UiHeading02 } from '@Src/components/Text';
import { navigate } from 'gatsby';
import * as styles from './index.module.scss';
export interface FormData {
	label: string;
	firstname: string;
	lastname: string;
	company: string;
	selectDummy: string;
}

export interface ResponseData {
	data: {
		inlineMessage: string;
	};
}

export interface Props {
	onResponse?: (value: string) => void;
}

const LabelInfoForm = ({ onResponse }: Props): ReactElement => {
	const [postError, setPostError] = useState<boolean>(false);
	const [submit, setSubmit] = useState(false);
	const selectOptions = ['option1', 'option2', 'option3'];

	const [label, setLabel] = useState('');

	const onSubmit = (event: any) => {
		event.preventDefault();
		localStorage.setItem('label', label);
		navigate('/signup/checkout');
		return false;
	};
	return (
		<div className={cx(styles.root, submit && styles.submit)}>
			<Link url={'/signup/email'} id={'back'}>
				<Text htmlText={'<< Back to e-mail'} />
			</Link>
			<form onSubmit={onSubmit}>
				<div className={styles.field}>
					<Input id="label" label="Label" onUpdate={(value) => setLabel(value)} />
					<span>(You can change this later)</span>
				</div>
				<div className={styles.post}>
					<BaseButton
						className={styles.submitButton}
						buttonType="submit"
						isLoading={submit}
						isConfirm={true}
						link={{
							url: '',
							id: 'submit',
							target: '_modal',
							label: 'Next: Payment',
							primary: true
						}}
					/>
					<span className={cx(styles.postError)}>
						{' '}
						{postError && <>Something went wrong sending your request. Please try again later.</>}{' '}
					</span>
				</div>
				<Link className={styles.submitButton} url={'/signup/email'} id={'back'} label={'Go back'}>
					Go back
				</Link>
			</form>
		</div>
	);
};

export default LabelInfoForm;
